import React, { useEffect, useState } from 'react'
import '../css/sidebar.css';
import { getCate } from "../redux/slices/cateSlice";
import { useDispatch, useSelector } from "react-redux";
function Sidebar({ updateidCate, updatePage, relativePost, slug, ...props }) {
    const dispatch = useDispatch();
    const [idCate, setIdCate] = useState(0);
    const { cates, loading } = useSelector((state) => state.cates);
    const hasRelativePosts = relativePost && relativePost.length > 0;
    const ChangeCate = (e) => {
        if (!slug) {
            updatePage(1);
            setIdCate(e);
            updateidCate(e)
        } else {
            window.location.replace('/cate/' + e);
        }
    }
    const ChangePost = (e) => {
        window.location.replace('/' + e);
    }
    useEffect(() => {
        dispatch(getCate());
    }, []);
    return (
        <>
            <div className="sidebar">
                <div className="accordion accordion-flush" id="accordionFlushSlider">
                    <div className="accordion-item ">
                        <h2 className="accordion-header" id="flush-headingOne">
                            <button
                                className="accordion-button collapsed  bg-dark text-light"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseOne"
                                aria-expanded="false"
                                aria-controls="flush-collapseOne"
                            >
                                Categories
                            </button>
                        </h2>
                        <div
                            id="flush-collapseOne"
                            className="accordion-collapse collapse"
                            aria-labelledby="flush-headingOne"
                            data-bs-parent="#accordionFlushSlider"
                        >
                            <div className="accordion-body border">
                                <ul className="list-group">
                                    {cates.length > 0 &&
                                        cates.map((item, index) => (
                                            <li key={index} className="list-group-item">
                                                <div className="input-group">
                                                    <div className="input-group-text">
                                                        <input
                                                            className="form-check-input mt-0"
                                                            type="radio"
                                                            defaultValue=""
                                                            name="categories"
                                                            checked={slug && slug == item.id || idCate && idCate == item.id}
                                                            onChange={() => ChangeCate(item.id)}
                                                            aria-label="Radio button for following text input"
                                                        />
                                                    </div>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        readOnly
                                                        value={item.name}
                                                        aria-label="Text input with radio button"
                                                    />
                                                </div>
                                            </li>
                                        ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {hasRelativePosts ? (
                    <div className="accordion accordion-flush mt-3" id="accordionFlushRelativePost">
                        <div className="accordion-item ">
                            <h2 className="accordion-header" id="flush-headingTwo">
                                <button
                                    className="accordion-button collapsed  bg-dark text-light"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseTwo"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseTwo"
                                >
                                    Relative Posts
                                </button>
                            </h2>
                            <div
                                id="flush-collapseTwo"
                                className="accordion-collapse collapse"
                                aria-labelledby="flush-headingTwo"
                                data-bs-parent="#accordionFlushRelativePost"
                            >
                                <div className="accordion-body border">
                                    <ul className="list-group">
                                        {relativePost.map((post, index) => (
                                            <div key={index} className="input-group mb-3">
                                                <div className="input-group-text">
                                                    <input
                                                        className="form-check-input mt-0"
                                                        type="radio"
                                                        value={post.slug}
                                                        onChange={(e) => ChangePost(e.target.value)}
                                                        aria-label="Checkbox for following text input"
                                                    />
                                                </div>
                                                <input
                                                    type="text"
                                                    value={post.name}
                                                    readOnly
                                                    className="form-control"
                                                    aria-label="Text input with checkbox"
                                                />
                                            </div>
                                        ))}
                                        <div>
                                        </div>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : ''}
            </div>
        </>
    )
}

export default Sidebar