import React, { useEffect, useState } from 'react'
import Sidebar from "../components/Sidebar";
import { useParams } from "react-router-dom"
import Navbar from "../components/Navbar";
import Post from "../components/Post";
import "../css/home.css";
function CatePost() {
    const [shouldHideSidebar, setShowHide] = useState(true);
    const updateShowHideSidebar = (value) => {
        setShowHide(value);
    };
    const api = process.env.REACT_APP_API_URL;
    const { id } = useParams();
    const [page, setPage] = useState(1);
    const [idCate, setIdCate] = useState(0);
    const [pageList, setPageList] = useState([]);
    const [posts, setPost] = useState([]);
    const updatePage = (value) => {
        setPage(value);
    }
    const updateidCate = (value) => {
        setIdCate(value);
    }
    const IncreasePage = () => {
        // if (page < pageList[pageList.length - 1]) {
        //     page = page + 1;
        //     setPage(page);
        // }
    }
    useEffect(() => {
        fetch(api + "postsCate/" + id + "?page=" + page)
            .then((res) => res.json())
            .then((res) => {
                var pages = [];
                for (let i = 1; i <= res.last_page; i++) {
                    pages.push(i);
                }
                setPost(res.data);
                setPageList(pages);

            });
    }, [id, page])
    return (
        <>
            <Navbar updateShowHideSidebar={updateShowHideSidebar} />
            <div className='row '>
                {shouldHideSidebar && (
                    <div className="col-md-2 mb-2">
                        <Sidebar updateidCate={updateidCate} slug={id} updatePage={updatePage} />
                    </div>
                )}

                <div className="col-md">
                    <div className="row">
                        {posts && posts.length > 0 && posts.map((item, index) => (
                            <div key={index} className="col-md-3  mb-2">
                                <div className="accordion accordion-flush border" id={"accordionFlushPost" + index}>
                                    <Post index={index} name={item.name} img={item.image} summary={item.summary} slug={item.slug} />
                                </div>
                            </div>

                        ))}
                    </div>
                </div>
            </div>
            {pageList.length > 0 && (
                <div className="row">
                    <div className="col"></div>
                    <div className="col-auto">
                        <nav aria-label="Page navigation example">
                            <ul className="pagination" id="pagination">
                                <li className="page-item">
                                    <a className="page-link disabled" href="#">
                                        Previous
                                    </a>
                                </li>
                                <li className="page-item">
                                    <select name="" onChange={(e) => setPage(e.target.value)} id="" className="form-control bg-primary text-light">
                                        {pageList.map((item, index) => (
                                            <option value={item} key={index} selected={item === page}>{item}</option>
                                        ))}
                                    </select>
                                </li>
                                <li className="page-item">
                                    <a className="page-link disabled" onClick={(e) => IncreasePage()} href="#">
                                        Next
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            )}
        </>
    )
}

export default CatePost