import { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import "../css/single.css";
import { Link } from 'react-router-dom';
import { useParams } from "react-router-dom"
function Detail() {
    const { id } = useParams();
    const [shouldHideSidebar, setShowHide] = useState(true);
    const api = process.env.REACT_APP_API_URL;
    const [posts, setPost] = useState([]);
    const [post, setPost1] = useState({});
    const updateShowHideSidebar = (value) => {
        setShowHide(value);
    };
    const updateidCate = (value) => {
        console.log(value);
    }
    useEffect(() => {
        fetch(api + 'post/' + id).then(res => res.json()).then((res) => {
            setPost(res.posts);
            setPost1(res.post);
        });
    }, [id])

    return (
        <>
            <Navbar updateShowHideSidebar={updateShowHideSidebar} />
            <div className='row' style={{ width: '100%' }}>
                <div className="col-md-2">
                    {shouldHideSidebar && (
                        <Sidebar updateidCate={updateidCate} slug={id} relativePost={posts} />
                    )}
                </div>
                <div className="col-md">
                    <div className="row">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><Link style={{ textDecoration: 'none' }} to="/">Home</Link></li>
                                <li class="breadcrumb-item active" aria-current="page">{post.title}</li>
                            </ol>
                        </nav>
                        <div dangerouslySetInnerHTML={{ __html: post.content }} />
                    </div>

                </div >

            </div >
        </>
    )
}

export default Detail