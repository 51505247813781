import React from 'react'
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import { useEffect, useState } from "react";
import "../css/home.css";
import Post from "../components/Post";
function Home() {
  const [page, setPage] = useState(1);
  const [shouldHideSidebar, setShowHide] = useState(true);
  const api = process.env.REACT_APP_API_URL;
  const [idCate, setIdCate] = useState(0);
  const [pageList, setPageList] = useState([]);
  const [posts, setPost] = useState([]);
  const updateidCate = (value) => {
    setIdCate(value);
  }
  const updatePage = (value) => {
    setPage(value);
  }
  const updateShowHideSidebar = (value) => {
    setShowHide(value);
  };
  const IncreasePage = () => {
    var page = page;
    page = page + 1;
    setPage(page);
  }
  useEffect(() => {
    if (idCate == 0) {
      fetch(api + "posts?page=" + page)
        .then((res) => res.json())
        .then((res) => {
          var pages = [];
          for (let i = 1; i <= res.last_page; i++) {
            pages.push(i);
          }
          setPageList(pages);
          setPost(res.data);
        });
    } else {
      fetch(api + "postsCate/" + idCate + "?page=" + page)
        .then((res) => res.json())
        .then((res) => {
          var pages = [];
          for (let i = 1; i <= res.last_page; i++) {
            pages.push(i);
          }
          setPageList(pages);
          setPost(res.data);
        });
    }


  }, [page, idCate]);
  return (
    <>
      <Navbar updateShowHideSidebar={updateShowHideSidebar} />
      <div className='row mt-3'>
        {shouldHideSidebar && (
          <div className="col-md-2 mb-2 ">
            <Sidebar updateidCate={updateidCate} updatePage={updatePage} />
          </div>
        )}

        <div className="col-md ">
          <div className="row">
            {posts.length > 0 && posts.map((item, index) => (
              <div key={index} className="col-md-3  mb-2">
                <div className="accordion accordion-flush border" id={"accordionFlushPost" + index}>

                  <Post index={index} name={item.name} img={item.image} summary={item.summary} slug={item.slug} />

                </div>
              </div>

            ))}
          </div>
          {pageList.length > 0 && (
            <div className="row">
              <div className="col"></div>
              <div className="col-auto">
                <nav aria-label="Page navigation example">
                  <ul className="pagination" id="pagination">
                    <li className="page-item">
                      <a className="page-link disabled" to="#">
                        Previous
                      </a>
                    </li>
                    <li className="page-item">
                      <select name="" onChange={(e) => setPage(e.target.value)} id="" className="form-control bg-primary text-light">
                        {pageList.map((item, index) => (
                          <option value={item} key={index} selected={item === page}>{item}</option>
                        ))}
                      </select>
                    </li>
                    <li className="page-item">
                      <a className="page-link disabled" onClick={(e) => IncreasePage()} to="#">
                        Next
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          )}

        </div>
      </div>

    </>
  )
}

export default Home