import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
export const getCate = createAsyncThunk('cates/getCate', async () => {
    return fetch("https://students.trungthanhweb.com/api/postcates")
        .then((res) => res.json());
});
const cateSlice = createSlice({
    name: 'cates',
    initialState: {
        cates: [],
        loading: false
    },
    extraReducers: builder => {
        builder
            .addCase(getCate.pending, (state) => {
                state.loading = true;
            })
            .addCase(getCate.fulfilled, (state, action) => {
                state.loading = false;
                state.cates = action.payload;
            })
            .addCase(getCate.rejected, (state) => {
                state.loading = false;
            });
    }
})
export default cateSlice.reducer;