import React, { useEffect, useState } from 'react'
import Dropdown from 'react-bootstrap/Dropdown';
import { getCate } from "../redux/slices/cateSlice";
import { useDispatch, useSelector } from "react-redux";
function Navbar({ updateShowHideSidebar }) {
    const dispatch = useDispatch();
    const [show, setShow] = useState(true);
    const [slug, setSlug] = useState('');

    const changeStatus = () => {
        const newShow = !show; // Toggle the show state
        setShow(newShow);
        updateShowHideSidebar(newShow);
    };
    const SearchPost = (e) => {
        if (slug != '') {
            window.location.replace('/posts/' + slug);
        }
    }
    const { cates, loading } = useSelector((state) => state.cates);
    useEffect(() => {
        dispatch(getCate());
    }, []);
    return (

        <>
            {/* eslint-disabled */}
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="container-fluid">
                    {/* eslint-disabled */}
                    <a className="navbar-brand" href="/">
                        <img src="https://cdn.iconscout.com/icon/free/png-256/free-bot-136-504893.png" width="30" height="30" className="d-inline-block align-top rounded-circle" alt="" />
                    </a>

                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon" />
                    </button>

                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            {cates.length > 0 && (
                                <Dropdown>
                                    <Dropdown.Toggle variant="success-outline" id="dropdown-basic">
                                        List
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        {cates.map((item, index) => (
                                            <Dropdown.Item key={index} href={"/cate/" + item.id}>{item.name}</Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            )}
                        </ul>
                        <div className="d-flex">
                            <button onClick={(e) => changeStatus()} className='btn btn-outline-primary me-2'><i class="bi bi-list-ul"></i></button>
                            <input
                                className="form-control me-2"
                                type="search"
                                placeholder="Search"
                                aria-label="Search"
                                onChange={(e) => setSlug(e.target.value)}
                            />
                            <button className="btn btn-outline-success" onClick={(e) => SearchPost()} type="button">
                                Search
                            </button>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Navbar