import React from 'react';
import { Link } from 'react-router-dom';
function Post(props) {
    const image = process.env.REACT_APP_IMG_URL;
    return (
        <>
            <div className="accordion-item">
                <h2 className="accordion-header" id={"flush-heading" + props.index}>
                    <button style={{ wordBreak: 'break-word' }}
                        className="accordion-button collapsed w-100"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={"#flush-collapse" + props.index}
                        aria-expanded="false"
                        aria-controls={"flush-collapse" + props.index}
                    >
                        {props.name}
                    </button>
                </h2>
                <div
                    id={"flush-collapse" + props.index}
                    className="accordion-collapse collapse"
                    aria-labelledby={"flush-heading" + props.index}
                    data-bs-parent="#accordionFlushPost"
                >
                    <div className="accordion-body" style={{ height: '332px' }}>
                        <div className="card" style={{ width: "100%", border: 'none' }}>
                            <div className='row'>
                                <Link to={"/" + props.slug}> <img src={image + props.img} className='image' alt="..." /> </Link>
                            </div>
                            <div className="card-body">
                                <p className="card-text text-center">
                                    {props.summary}
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div >
        </>
    );
}

export default Post;