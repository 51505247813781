import React from 'react'

function Main() {
    return (
        <>
            <section id="hero" className="d-flex flex-column justify-content-center">
                <div className="container">
                    <h1>NGUYEN LE TRUNG THANH</h1>
                    <p>
                        I'm{" "}
                        <span
                            className="typed"
                            data-typed-items="Designer, Developer, Freelancer, Photographer"
                        />
                    </p>
                    <div className="social-links">
                        <a href="#" className="twitter">
                            <i className="bx bxl-twitter" />
                        </a>
                        <a href="#" className="facebook">
                            <i className="bx bxl-facebook" />
                        </a>
                        <a href="#" className="instagram">
                            <i className="bx bxl-instagram" />
                        </a>
                        <a href="#" className="google-plus">
                            <i className="bx bxl-skype" />
                        </a>
                        <a href="#" className="linkedin">
                            <i className="bx bxl-linkedin" />
                        </a>
                    </div>
                </div>
            </section>
            <main id="main">
                <section id="about" className="about">
                    <div className="container" data-aos="fade-up">
                        <div className="section-title">
                            <h2>About</h2>
                            <p>
                                I'm a fullstack developer. I have over 3 years of experience. I can work with different libraries and frameworks such as : ReactJs, NextJs, Laravel, CodeIgniter
                            </p>
                        </div>
                        <div className="row">
                            <div className="col-lg-4">
                                <img
                                    src="assets/img/profile-img.jpg"
                                    className="img-fluid"
                                    alt=""
                                />
                            </div>
                            <div className="col-lg-8 pt-4 pt-lg-0 content">
                                <h3> Web Developer.</h3>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <ul>
                                            <li>
                                                <i className="bi bi-chevron-right" />{" "}
                                                <strong>Birthday:</strong> <span>3 April 1997</span>
                                            </li>
                                            <li>
                                                <i className="bi bi-chevron-right" />{" "}
                                                <strong>Website:</strong> <a href='https://trungthanhweb.com'>https://trungthanhweb.com</a>
                                            </li>
                                            <li>
                                                <i className="bi bi-chevron-right" />{" "}
                                                <strong>Phone:</strong> <span>+84 9777 666 53</span>
                                            </li>
                                            <li>
                                                <i className="bi bi-chevron-right" /> <strong>City:</strong>{" "}
                                                <span>Ho Chi Minh City</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-6">
                                        <ul>
                                            <li>
                                                <i className="bi bi-chevron-right" /> <strong>Age:</strong>{" "}
                                                <span>27</span>
                                            </li>
                                            <li>
                                                <i className="bi bi-chevron-right" />{" "}
                                                <strong>Degree:</strong> <span>Bachelor</span>
                                            </li>
                                            <li>
                                                <i className="bi bi-chevron-right" />{" "}
                                                <strong>PhEmailone:</strong> <span>leodomsolar@gmail.com</span>
                                            </li>
                                            <li>
                                                <i className="bi bi-chevron-right" />{" "}
                                                <strong>Long term position:</strong> <span>Available</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p>
                                    I'm looking for a full time position at a company  where I have chance to work and improve my skill for a long term. I can work in both back end and front end.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                {/* End About Section */}
                {/* ======= Skills Section ======= */}
                <section id="skills" className="skills section-bg">
                    <div className="container" data-aos="fade-up">
                        <div className="section-title">
                            <h2>Skills</h2>

                        </div>
                        <div className="row skills-content">
                            <div className="col-lg-6">
                                <img src='Screenshot_1.png' />
                            </div>
                            <div className="col-lg-6">
                                <ul className='pt-5'>
                                    <ul className="list-group">
                                        <li className="list-group-item">HTML CSS</li>
                                        <li className="list-group-item">JavaScript</li>
                                        <li className="list-group-item">PHP</li>
                                        <li className="list-group-item">ReactJs</li>
                                        <li className="list-group-item">NextJs</li>
                                    </ul>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                {/* End Skills Section */}
                {/* ======= Resume Section ======= */}
                <section id="resume" className="resume">
                    <div className="container" data-aos="fade-up">
                        <div className="section-title">
                            <h2>Resume</h2>
                        </div>
                        <div className="row">
                            <div className="col-lg-6">
                                <h3 className="resume-title">Sumary</h3>
                                <div className="resume-item pb-0">
                                    <h4>NGUYEN LE TRUNG THANH</h4>
                                    <p>
                                        <em>
                                            Innovative and deadline-driven Web Designer with 3+ years
                                            of experience designing and developing user-centered
                                            digital/print marketing material from initial concept to
                                            final, polished deliverable.
                                        </em>
                                    </p>
                                    <ul>
                                        <li>Binh Thanh, Ho Chi Minh city</li>
                                        <li>(84) 9777 666 53</li>
                                        <li>leodomsolar@gmail.com</li>
                                    </ul>
                                </div>
                                <h3 className="resume-title">Education</h3>
                                <div className="resume-item">
                                    <h4>Bachelor of Fine Arts &amp; Graphic Design</h4>
                                    <h5>2015 - 2019</h5>
                                    <p>
                                        <em>Bachelor of Information Technology - UIH</em>
                                    </p>
                                </div>
                                <div className="resume-item">
                                    <h4>Bachelor of Fine Arts &amp; Graphic Design</h4>
                                    <h5>2020 - 2022</h5>
                                    <p>
                                        <em>Bachelor of Web Design - FPT</em>
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <h3 className="resume-title">Professional Experience</h3>
                                <div className="resume-item">
                                    <h4>Web Manager</h4>
                                    <h5>July 2021 - May 2022</h5>
                                    <p>
                                        <em> Ha Vang Company - Binh Thanh, Ho Chi Minh city </em>
                                    </p>
                                    <ul>
                                        <li>
                                            Manager of a wordpress web
                                        </li>
                                        <li>
                                            Build website with laravel framework
                                        </li>
                                    </ul>
                                </div>
                                <div className="resume-item">
                                    <h4>PHP DEVELOPER</h4>
                                    <h5>July 2022 - Jan 2023</h5>
                                    <p>
                                        <em>Leontec</em>
                                    </p>
                                    <ul>
                                        <li>
                                            Build an API with laravel that can CRUD an object and return the responses
                                        </li>
                                        <li>
                                            Build views of admin website with VueJS library and help the admin can manage the products the bills, etc ..
                                        </li>
                                        <li>
                                            Run in the inside network,
                                        </li>
                                    </ul>
                                </div>
                                <div className="resume-item">
                                    <h4>FREELANCER AND DEVELOPMENT MENTOR</h4>
                                    <h5>Sep 2023 - Now</h5>
                                    <p>
                                        <em></em>
                                    </p>
                                    <ul>
                                        <li>
                                            Develop the curriculum for the Fullstack Developer course.


                                        </li>
                                        <li>Courses include:

                                            Basic HTML, CSS, JS

                                            Basic PHP

                                            ReactJS Library

                                            PHP Framework: Laravel Framework</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="portfolio" className="portfolio section-bg">
                    <div className="container" data-aos="fade-up">
                        <div className="section-title">
                            <h2>Demo Product</h2>
                        </div>
                        <div className="row">
                        </div>
                        <div
                            className="row portfolio-container"
                            data-aos="fade-up"
                            data-aos-delay={200}
                        >
                            <div className="col-lg-4 col-md-6 portfolio-item filter-app">
                                <div className="portfolio-wrap">
                                    <img style={{ height: '220px !important' }}
                                        src="https://repository-images.githubusercontent.com/456963513/82528385-a73f-488f-9003-513321283a6b"
                                        className="img-fluid"
                                        alt=""
                                    />
                                    <div className="portfolio-info">
                                        <h4>Ecommerce Website</h4>
                                        <div className="portfolio-links">
                                            <a
                                                href="https://products.trungthanhweb.com/"
                                                data-gallery="portfolioGallery"
                                                className="portfolio-lightbox"
                                                title="App 1"
                                            >
                                                <i className="bx bx-plus" />
                                            </a>
                                            <a
                                                href="https://products.trungthanhweb.com/"
                                                className="portfolio-details-lightbox"
                                                data-glightbox="type: external"
                                                title="Portfolio Details"
                                            >
                                                <i className="bx bx-link" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 portfolio-item filter-web">
                                <div className="portfolio-wrap">
                                    <img
                                        style={{ height: '220px !important' }}
                                        src="https://www.bootstrapdash.com/blog/wp-content/uploads/2020/04/gaming-website-template.jpg"
                                        className="img-fluid"
                                        alt=""
                                    />
                                    <div className="portfolio-info">
                                        <h4>Funny Website</h4>
                                        <div className="portfolio-links">
                                            <a
                                                href="https://demo.trungthanhweb.com/"
                                                data-gallery="portfolioGallery"
                                                className="portfolio-lightbox"
                                                title="Web 3"
                                            >
                                                <i className="bx bx-plus" />
                                            </a>
                                            <a
                                                href="https://demo.trungthanhweb.com/"
                                                className="portfolio-details-lightbox"
                                                data-glightbox="type: external"
                                                title="Portfolio Details"
                                            >
                                                <i className="bx bx-link" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 portfolio-item filter-web">
                                <div className="portfolio-wrap">
                                    <img

                                        src="https://repository-images.githubusercontent.com/534599230/33298306-6c8f-4f1e-a038-84228f700b10"
                                        className="img-fluid"
                                        alt=""
                                        style={{ height: '220px' }}
                                    />
                                    <div className="portfolio-info">
                                        <h4>Education Website</h4>
                                        <div className="portfolio-links">
                                            <a
                                                href="https://news.trungthanhweb.com/"
                                                data-gallery="portfolioGallery"
                                                className="portfolio-lightbox"
                                                title="Web 3"
                                            >
                                                <i className="bx bx-plus" />
                                            </a>
                                            <a
                                                href="https://news.trungthanhweb.com/"
                                                className="portfolio-details-lightbox"
                                                data-glightbox="type: external"
                                                title="Portfolio Details"
                                            >
                                                <i className="bx bx-link" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="contact" className="contact">
                    <div className="container" data-aos="fade-up">
                        <div className="section-title">
                            <h2>Contact</h2>
                        </div>
                        <div className="row mt-1">
                            <div className="col-lg-4">
                                <div className="info">
                                    <div className="address">
                                        <i className="bi bi-geo-alt" />
                                        <h4>Location:</h4>
                                        <p>Binh Thanh , Ho Chi Minh city</p>
                                    </div>
                                    <div className="email">
                                        <i className="bi bi-envelope" />
                                        <h4>Email:</h4>
                                        <p>leodomsolar@gmail.com</p>
                                    </div>
                                    <div className="phone">
                                        <i className="bi bi-phone" />
                                        <h4>Call:</h4>
                                        <p>+84 9777 666 53</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8 mt-5 mt-lg-0">
                                <form
                                    action="forms/contact.php"
                                    method="post"
                                    role="form"
                                    className="php-email-form"
                                >
                                    <div className="row">
                                        <div className="col-md-6 form-group">
                                            <input
                                                type="text"
                                                name="name"
                                                className="form-control"
                                                id="name"
                                                placeholder="Your Name"
                                                required=""
                                            />
                                        </div>
                                        <div className="col-md-6 form-group mt-3 mt-md-0">
                                            <input
                                                type="email"
                                                className="form-control"
                                                name="email"
                                                id="email"
                                                placeholder="Your Email"
                                                required=""
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group mt-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="subject"
                                            id="subject"
                                            placeholder="Subject"
                                            required=""
                                        />
                                    </div>
                                    <div className="form-group mt-3">
                                        <textarea
                                            className="form-control"
                                            name="message"
                                            rows={5}
                                            placeholder="Message"
                                            required=""
                                            defaultValue={""}
                                        />
                                    </div>
                                    <div className="my-3">
                                        <div className="loading">Loading</div>
                                        <div className="error-message" />
                                        <div className="sent-message">
                                            Your message has been sent. Thank you!
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <button disabled type="submit">Send Message</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                {/* End Contact Section */}
            </main>
        </>

    )
}

export default Main