import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import Detail from './pages/Detail';
import CatePost from './pages/CatePost';
import SearchPost from './pages/SearchPost';
import Login from './pages/Login';
import Resume from './pages/Resume';
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {/* <Route path='/' element={<Login />} /> */}
          <Route path='/resume' element={<Resume />} />
          <Route path='/' element={<Home />} />
          <Route path='/:id' element={<Detail />} />
          <Route path='/post/:id' element={<Detail />} />
          <Route path='/cate/:id' element={<CatePost />} />
          <Route path='/posts/:id' element={<SearchPost />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
