import React from 'react'
import "../assets/css/style.css";
import "../assets/vendor/boxicons/css/boxicons.min.css";
import Header from '../components/resume/Header';
import Main from '../components/resume/Main';
function Resume() {
    return (
        <>
            <Header />
            <Main />
        </>
    )
}

export default Resume